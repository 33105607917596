import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on } from '@ngrx/store';
import { Profile } from '../../models';

import * as actions from '../actions/auth.action';

export interface AuthState {
  user: Profile | undefined;
  authChecked: boolean;
  authChecking: boolean;
  signedIn: boolean;
  signingIn: boolean;
  SignIngOut: boolean;
  registerSuccess: boolean;
  registering: boolean;
  resetSuccess: boolean;
  resetting: boolean;
  error: HttpErrorResponse | null | undefined;
  registerError: HttpErrorResponse | null | undefined;
  resetError: HttpErrorResponse | null | undefined;
}

export const initialState: AuthState = {
  user: undefined,
  authChecked: false,
  authChecking: false,
  signedIn: false,
  signingIn: false,
  SignIngOut: false,
  registering: false,
  registerSuccess: false,
  resetting: false,
  resetSuccess: false,
  error: null,
  registerError: null,
  resetError: null,
};

export const authReducer = createReducer(
  initialState,
  on(actions.CheckAuth, (state: AuthState) => {
    return {
      ...state,
      authChecked: false,
      authChecking: true,
    };
  }),
  on(actions.CheckAuthSuccess, (state: AuthState, { auth }) => {
    return {
      ...state,
      user: auth.user,
      authChecked: true,
      authChecking: false,
      signedIn: auth.isAuth,
      signingIn: false,
      error: null,
    };
  }),
  on(actions.CheckAuthFail, (state: AuthState, { error }) => {
    return {
      ...state,
      user: undefined,
      authChecked: true,
      signedIn: false,
      signingIn: false,
      error,
    };
  }),
  on(actions.SignIn, (state: AuthState, { loginData }) => {
    return {
      ...state,
      authChecked: false,
      signingIn: true,
    };
  }),
  on(actions.SignInSuccess, (state: AuthState, { auth }) => {
    return {
      ...state,
      authChecked: true,
      user: auth.user,
      signedIn: auth.isAuth,
      signingIn: false,
      error: null,
    };
  }),
  on(actions.SignInFail, (state: AuthState, { error }) => {
    return {
      ...state,
      user: undefined,
      authChecked: true,
      signedIn: false,
      signingIn: false,
      error,
    };
  }),
  on(actions.SignOut, (state: AuthState) => {
    return {
      ...state,
      authChecked: false,
      SignIngOut: true,
    };
  }),
  on(actions.SignOutSuccess, (state: AuthState, { result: auth }) => {
    return {
      ...state,
      authChecked: true,
      user: undefined,
      signedIn: false,
      signingIn: false,
      SignIngOut: false,
      error: null,
      registerError: null,
    };
  }),
  on(actions.SignOutFail, (state: AuthState, { error }) => {
    return {
      ...state,
      authChecked: true,
      signedIn: true,
      SignIngOut: false,
      error,
    };
  }),
  on(actions.Register, (state: AuthState) => {
    return {
      ...state,
      registering: true,
      registerSuccess: false,
      registerError: null,
    };
  }),
  on(actions.RegisterSuccess, (state: AuthState, { result: auth }) => {
    return {
      ...state,
      authChecked: true,
      user: auth.user,
      registering: false,
      registerSuccess: true,
      signedIn: false,
      signingIn: false,
      error: null,
      registerError: null,
    };
  }),
  on(actions.RegisterFail, (state: AuthState, { error }) => {
    return {
      ...state,
      authChecked: false,
      registering: false,
      registerSuccess: false,
      registerError: error,
    };
  }),
  on(actions.ResetCode, (state: AuthState) => {
    return {
      ...state,
      reseting: true,
      resetSuccess: false,
      resetError: null,
    };
  }),
  on(actions.ResetCodeSuccess, (state: AuthState, { result: auth }) => {
    return {
      ...state,
      authChecked: true,
      user: auth.user,
      reseting: false,
      resetSuccess: true,
      signedIn: false,
      signingIn: false,
      error: null,
      resetError: null,
    };
  }),
  on(actions.ResetCodeFail, (state: AuthState, { error }) => {
    return {
      ...state,
      authChecked: false,
      reseting: false,
      resetSuccess: false,
      resetError: error,
    };
  }),
  on(actions.RemoveErrors, (state: AuthState) => {
    return {
      ...state,
      error: null,
      registerError: null,
    };
  })
);

export const getAuthUser = (state: AuthState) => state.user;
export const getAuthChecked = (state: AuthState) => state.authChecked;
export const getAuthChecking = (state: AuthState) => state.authChecking;
export const getAuthSignedIn = (state: AuthState) => state.signedIn;
export const getAuthSigningIn = (state: AuthState) => state.signingIn;
export const getAuthError = (state: AuthState) => state.error;
export const getUser = (state: AuthState) => state.user;

export const getRegistering = (state: AuthState) => state.registering;
export const getRegisterSuccess = (state: AuthState) => state.registerSuccess;
export const getRegisterError = (state: AuthState) => state.registerError;

export const getResetting = (state: AuthState) => state.resetting;
export const getResetSuccess = (state: AuthState) => state.resetSuccess;
export const getResetError = (state: AuthState) => state.resetError;
